<template>
  <div>
    <div
      v-if="leads.length"
      class="grid-container"
    >
      <b-card
        v-for="lead in leads"
        :key="lead.id"
        class="lead-card ecommerce-card ecom overflow-visible"
        no-body
      >
        <img
          :src="lead.smallLogoUrl || getDefaultProductImage(300, 250)"
          alt="Logo"
          class="lead-logo"
          @error="imageError"
        >
        <b-card-body class="d-flex flex-column">
          <div class="flex-grow-1">
            <div class="lead-name">
              {{ lead.storeName }}
            </div>
            <div class="lead-category">
              {{ lead.category }}
            </div>
            <hr class="lead-divider">
            <b-card-text class="lead-location">
              <feather-icon
                icon="MapPinIcon"
                size="12"
              />
              <span class="country-text"> {{ displayLocation(lead) }}</span>
            </b-card-text>
            <div class="lead-insights">
              <div class="lead-insights-title mt-1">
                Leads Insights
              </div>
              <b-card-text class="text mb-0">
                <strong class="mt-1">Store Type:</strong>
                {{ lead.storeTypes.join(', ') || 'N/A' }}
              </b-card-text>
              <b-card-text class="text mt-1 mb-1">
                <strong>AVG Retail Price:</strong>
                {{ lead.averageRetailPrice.join(', ') || 'N/A' }}
              </b-card-text>
            </div>
          </div>
          <div class="button-container">
            <b-button
              variant="primary"
              class="details-btn"
              @click="viewDetails(lead)"
            >
              See details and contacts
            </b-button>
          </div>
        </b-card-body>
      </b-card>
    </div>
    <div
      v-else
      class="no-leads-message d-flex justify-content-center align-items-center"
    >
      <div class="text-center font-weight-bold h2">
        <div v-if="filterFailed">
          Sorry, no matches were found.
          <div>Try a new filter.</div>
        </div>
        <div v-else-if="searchText">
          Sorry, no matches were found.
          <div>Try a new search.</div>
        </div>
        <div v-else>
          No leads found.
        </div>
      </div>
    </div>
    <div
      v-if="leads.length"
      class="pagination-container"
    >
      <b-row>
        <b-col
          cols="12"
          class="d-flex align-items-center justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalLeads"
            :per-page="pageSize"
            page-class="hidden"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="pageChanged"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BPagination,
  BCard,
  BCardBody,
  BButton,
  BCardText,
} from 'bootstrap-vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import { getDefaultProductImage } from '@/@core/utils/utils'

export default {
  components: {
    BRow,
    BCol,
    BPagination,
    BCard,
    BCardBody,
    BButton,
    FeatherIcon,
    BCardText,
  },
  props: {
    leads: {
      type: Array,
      default: () => [],
    },
    totalLeads: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    searchText: {
      type: String,
      default: '',
    },
    pageSize: {
      type: Number,
      default: 3,
    },
    filterFailed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      getDefaultProductImage,
    }
  },
  methods: {
    pageChanged(page) {
      this.$emit('pageChanged', page)
    },
    viewDetails(lead) {
      analytics.track(
        constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLICKS_CONTACT_BUTTON,
        {
          leadId: lead.id,
          lead_storeName: lead.storeName,
        },
      )
      this.$router.push({ name: 'leadAI/detail', params: { leadId: lead.id } })
    },
    displayLocation(leadDetails) {
      if (!leadDetails.country) {
        return 'N/A'
      }
      if (!leadDetails.locality) {
        return leadDetails.country
      }
      return `${leadDetails.country} - ${leadDetails.locality}`
    },
    imageError(e) {
      e.target.src = getDefaultProductImage(300, 250)
    },
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}
.lead-card {
  background: #fff;
  border: 1px solid #e0e0e0;
  padding: 0;
  text-align: left;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}
.lead-logo {
  width: 100%;
  height: 250px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid #e0e0e0;
  object-fit: contain;
}
.lead-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
  font: manrope;
}
.lead-category {
  font-size: 12px;
  color: #090909;
}
.lead-location {
  font-size: 12px;
  color: #666;
  display: flex;
  align-items: center;
}
.lead-location feather-icon {
  margin-right: 5px;
}
.lead-insights {
  margin-top: 20px;
}
.lead-insights-title {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 5px;
}
.lead-insights b-card-text {
  font-size: 14px;
  margin-bottom: 5px;
}
.button-container {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.lead-divider {
  border: none;
  border-top: 1px solid #333;
  margin: 10px 0;
  margin-bottom: 10px;
}
.details-btn {
  width: 100%;
  padding: 10px 0; /* Add space above and below the button */
  background-color: var(--primary-color); /* Use primary color for buttons */
  color: #fff;
  border: none;
  border-radius: 5px; /* Small curve */
  cursor: pointer;
}
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
.no-leads-found {
  text-align: center;
  margin-top: 50px;
  font-size: large;
  font: manrope;
}
.country-text {
  margin-left: 3px;
  font: manrope;
}
.mild-divider {
  border: none;
  border-top: 1px solid #e0e0e0;
  margin: 0;
}
.no-leads-message {
  height: inherit;
  min-height: 200px;
}

ul.pagination {
  li[role="separator"] {
    display: none;
  }
}
</style>
