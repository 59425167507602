import { ref, computed } from '@vue/composition-api'

import {
 GET_LEADAI_LIST, RESET_FILTERS, UPDATE_APPLIED_FILTERS, UPDATE_CURRENT_PAGE, UPDATE_SEARCH_TEXT,
 UPDATE_SELECTED_CATEGORIES,
 UPDATE_SELECTED_COUNTRY,
 UPDATE_SELECTED_RETAIL_PRICES,
 UPDATE_SELECTED_STORE_TYPES,
} from '@/store/modules/leadai.module'
import { apiToastError } from '@/@core/utils/toast'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'

export default function useLeadAI({ dispatch, state, commit }) {
  const leads = ref([])
  const totalLeads = ref(0)
  const pageSize = ref(3)
  const loading = ref(false)
  const filterFailed = ref(false)

  const currentPage = computed(() => state.currentPage)
  const searchText = computed({
      get: () => state.searchText,
      set: newText => {
        commit(UPDATE_SEARCH_TEXT, newText)
      },
  })
  const selectedCountry = computed({
    get: () => state.selectedCountry,
    set: updatedSelection => {
      commit(UPDATE_SELECTED_COUNTRY, updatedSelection)
    },
  })
  const selectedCategories = computed({
    get: () => state.selectedCategories,
    set: updatedSelection => {
      commit(UPDATE_SELECTED_CATEGORIES, updatedSelection)
    },
  })
  const selectedStoreTypes = computed({
    get: () => state.selectedStoreTypes,
    set: updatedSelection => {
      commit(UPDATE_SELECTED_STORE_TYPES, updatedSelection)
    },
  })
  const selectedRetailPrices = computed({
    get: () => state.selectedRetailPrices,
    set: updatedSelection => {
      commit(UPDATE_SELECTED_RETAIL_PRICES, updatedSelection)
    },
  })
  const validateFilters = () => {
    const shouldPersistFilters = localStorage.getItem('persistFilter')
    if (!shouldPersistFilters) {
      commit(RESET_FILTERS)
    } else {
      localStorage.removeItem('persistFilter')
    }
  }

  const fetchLeads = () => {
    loading.value = true
    return dispatch(GET_LEADAI_LIST, {
      pageSize: pageSize.value,
    })
      .then(response => {
        leads.value = response.data.data
        totalLeads.value = response.data.totalCount
        filterFailed.value = !leads.value.length
      })
      .catch(err => {
        console.error(err)
        apiToastError('Failed to load. Please try again later.')
      })
      .finally(() => {
        loading.value = false
      })
  }

  const handlePageChange = page => {
    if (page < state.currentPage) {
      analytics.track(constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLICKS_LEFT_ARROW)
    } else if (page > state.currentPage) {
      analytics.track(
        constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLICKS_RIGHT_ARROW,
      )
    }
    commit(UPDATE_CURRENT_PAGE, page)
    fetchLeads()
  }

  const searchLeads = newSearchText => {
    commit(UPDATE_SEARCH_TEXT, newSearchText)
    commit(UPDATE_CURRENT_PAGE, 1)
    fetchLeads()
  }

  const handleFilterChange = () => {
    const filters = {
      countries: state.selectedCountry,
      storeTypes: state.selectedStoreTypes,
      categories: state.selectedCategories,
      avgRetailPrice: state.selectedRetailPrices,
    }
    const validFilters = Object.fromEntries(
      Object.entries(filters).filter(([, value]) => value?.length),
    )
    commit(UPDATE_APPLIED_FILTERS, validFilters)
    commit(UPDATE_CURRENT_PAGE, 1)
    fetchLeads()
  }

  const handleResetFilters = (filters = {}, newSearchText = '') => {
    commit(UPDATE_SEARCH_TEXT, newSearchText)
    commit(UPDATE_APPLIED_FILTERS, filters)
    fetchLeads()
  }

  return {
    leads,
    totalLeads,
    pageSize,
    loading,
    filterFailed,
    fetchLeads,
    handlePageChange,
    searchLeads,
    handleFilterChange,
    handleResetFilters,
    validateFilters,
    currentPage,
    searchText,
    selectedCategories,
    selectedCountry,
    selectedRetailPrices,
    selectedStoreTypes,
  }
}
